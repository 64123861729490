import $ from 'jquery';

export default class Galleries {
    constructor() {
        this.$galleries = $('.js-gallery');
        if ( !this.$galleries.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            require('magnific-popup');

            this.$galleries.each((i, gallery) => {
                $(gallery).magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    gallery: {
                        enabled: true
                    }
                });
            });
        });
    }
}
