import $ from 'jquery';
import setIntervalAndExecute from '../helpers/set-interval-and-execute';

export default class Timeago {
    constructor(selector = '.js-timeago-diff') {
        this.$timeagos = $(selector);
        if ( !this.$timeagos.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            const moment = require('moment');

            moment.updateLocale('nl', {
                relativeTime: {
                    future: 'over %s',
                    past: '%s geleden',
                    s: 'een paar sec.',
                    m: 'één min.',
                    mm: '%d min.',
                    h: 'één uur',
                    hh: '%d uur',
                    d: 'één dag',
                    dd: '%d dagen',
                    M: 'één maand',
                    MM: '%d maanden',
                    y: 'één jaar',
                    yy: '%d jaar'
                }
            });

            // Set new thresholds
            const minutesInHour = 60,
                  hoursInDay = 24,
                  daysInMonth = 30,
                  monthsInYear = 12;

            moment.relativeTimeThreshold('s', minutesInHour);
            moment.relativeTimeThreshold('m', minutesInHour);
            moment.relativeTimeThreshold('h', hoursInDay);
            moment.relativeTimeThreshold('d', daysInMonth);
            moment.relativeTimeThreshold('M', monthsInYear);

            this.$timeagos.each((i, timeago) => {
                const $timeago = $(timeago),
                      interval = 1000;

                let tick = 0;
                setIntervalAndExecute(() => {
                    // Calculate difference and humanize using moment.js
                    const current = $timeago.data('timeago-current'),
                          target = $timeago.data('timeago-begin') + tick,
                          diff = moment.duration(moment(target, 'X').diff(moment(current, 'X'))).humanize(true);

                    $timeago.text(diff);
                    tick--;
                }, interval);
            });
        });
    }
}
