import $ from 'jquery';

export default class FHCollections {
    constructor(selector = '[data-prototype]') {
        this.$collections = $(selector);
        if ( !this.$collections.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        require.ensure([], (require) => {
            require('symfony-collection/jquery.collection.js');

            /* eslint-disable camelcase */
            this.$collections.each((i, collection) => {
                $(collection).collection({
                    name_prefix: 'fh',
                    add: '<a href="#">Bestuurder toevoegen</a>',
                    allow_remove: false,
                    allow_up: false,
                    allow_down: false,
                    add_at_the_end: true
                });
            });
        });
    }
}
