import $ from 'jquery';

export default class Notifications {
    constructor(selector = '.js-notification') {
        this.$notifications = $(selector);
        if ( !this.$notifications.length ) {
            return;
        }

        this.bootstrapNotify();
    }

    bootstrapNotify() {
        require.ensure([], (require) => {
            require('bootstrap-notify');

            /* eslint-disable camelcase */
            this.$notifications.each((i, notification) => {
                const $notification = $(notification);

                $.notify({
                    // Options
                    message: $notification.data('message')
                }, {
                    // Settings
                    element: 'body',
                    type: $notification.data('type'),
                    allow_dismiss: true,
                    placement: {
                        from: 'top',
                        align: 'center'
                    },
                    offset: 20,
                    spacing: 10,
                    z_index: 1031,
                    delay: 0,
                    animate: {
                        enter: 'animated fadeInDown',
                        exit: 'animated fadeOutUp'
                    },
                    icon_type: 'class',
                    template: [
                        '<div class="container" data-notify="container">',
                            '<div class="notification is-{0}" role="alert">{2} <button type="button" class="notification-close" data-notify="dismiss">&times;</button></div>',
                        '</div>'
                    ].join('\n')
                });
            });
        });
    }
}
