import $ from 'jquery';
import { debounce } from 'throttle-debounce';

export default class AddressRetriever {
    constructor(selector = '.js-address-retriever') {
        this.selector = selector;
        this.$retrievers = $(this.selector);
        if ( !this.$retrievers.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        const delay = 500;

        this.$retrievers.on('input change blur', 'input', debounce(delay, false, (e) => {
            const $retriever = $(e.currentTarget).closest(this.selector),
                  postalcode = $retriever.find('.address-postalcode').val(),
                  housenumber = $retriever.find('.address-housenumber').val(),
                  current = [postalcode, housenumber].join();

            if ( typeof $retriever.data('current') === 'undefined' ) {
                $retriever.data('current', current);
            }

            if ( typeof $retriever.data('current') === 'undefined' ) {
                $retriever.data('current', current);
            }

            if ( !postalcode || !housenumber || $retriever.data('current') === current ) {
                return;
            }

            $retriever.data('current', current);
            const $street = $retriever.find('.address-street'),
                  $city = $retriever.find('.address-city');

            $street.closest('.form-group').addClass('is-loading');
            $city.closest('.form-group').addClass('is-loading');

            $.ajax({
                method: 'GET',
                url: $retriever.find('[data-fetch-address-url]').data('fetch-address-url'),
                data: {
                    postalCode: postalcode,
                    houseNumber: housenumber
                }
            }).done((response) => {
                $street.val(response.street);
                $city.val(response.city);
            }).always(() => {
                $street.closest('.form-group').removeClass('is-loading');
                $city.closest('.form-group').removeClass('is-loading');
            });
        })).find('input').trigger('change');
    }
}
