import $ from 'jquery';

export default class AddRequestHandler {
    constructor() {
        this.addRequestToggle('.js-toggle-problem', '.js-toggle-nearest-garage, .js-toggle-number-persons, .js-toggle-gearing, .js-toggle-transmission, .js-toggle-planned, .js-toggle-destination', '6');
        this.addRequestToggle('.js-toggle-nearest-garage', '.js-toggle-destination', '0');
        this.addRequestToggle('.js-toggle-location', '.js-toggle-address', 'address');
        this.addRequestToggle('.js-toggle-location', '.js-toggle-hectometre', 'highway_location_marker');
        this.addRequestToggle('.js-toggle-location', '.js-toggle-postal-code', 'postal_code');
        this.addRequestToggle('.js-toggle-location', '.js-toggle-coordinates', 'coordinates');
        this.initializeLicensePlate();

        // Fix request toggle for nearest garage selection
        $('.js-toggle-problem').find(':input').on('change', (e) => {
            if ( $(e.currentTarget).val() !== '6' ) {
                return;
            }

            const $checked = $('.js-toggle-nearest-garage').find(':input:checked');
            if ( !$checked.length ) {
                $('.js-toggle-destination').hide();
            }

            $checked.trigger('change');
        });
    }

    addRequestToggle(toggleElement, elementsToToggle, toggleValue) {
        const $toggleElements = $(toggleElement),
              $hideElements = $(elementsToToggle);

        if ( !$toggleElements.length ) {
            return;
        }

        $hideElements.hide().removeClass('is-visible');
        $toggleElements.each((i, toggleElement) => {
            const $toggleElement = $(toggleElement),
                  $allFields = $toggleElement.parent().find(elementsToToggle);

            $toggleElement.find(':input').on('change', (e) => {
                const state = $(e.currentTarget).val() === toggleValue;
                $allFields.toggle(state).toggleClass('is-visible', state);

                $(document).trigger('maps:update');
            });
        });

        $toggleElements.find(':input').each((i, input) => {
            const $input = $(input);

            if ( $input.is(':checked, select') ) {
                $input.trigger('change');
            }
        });
    }

    initializeLicensePlate() {
        const $licensePlate = $('.js-license-plate');
        if ( !$licensePlate.length ) {
            return;
        }

        let value = $licensePlate.val();
        const $formGroup = $licensePlate.closest('.form-group'),
              $licensePlateInput = $licensePlate.find('input'),
              $carDetails = $('<div class="form-car-details"></div>').insertAfter($licensePlate);

        $licensePlateInput.on('blur', () => {
            if ( !$licensePlateInput.val() || value === $licensePlateInput.val() ) {
                return;
            }

            // Update value
            value = $licensePlateInput.val();

            // Update UI
            $carDetails.empty();
            $formGroup.removeClass('has-error');
            $licensePlate.addClass('is-loading');
            $licensePlateInput.val($licensePlateInput.val().toUpperCase());

            // Send request
            $.ajax({
                url: $licensePlate.data('endpoint'),
                data: {
                    licensePlate: $licensePlateInput.val()
                },
                method: 'POST',
                xhrFields: {
                    withCredentials: true
                },
                beforeSend: (xhr) => {
                    xhr.setRequestHeader('X-Csrf-Token', $licensePlate.data('csrf-token'));
                }
            }).done((response) => {
                const business = response.company ? `,<br><strong>Zakelijk:</strong> ${response.company.name}` : '';

                // Format value
                $licensePlateInput.val(response.licensePlate);
                value = $licensePlateInput.val();

                // Inject UI
                $carDetails.html(`
                    <span class="license-plate-car">
                        <span><i class="icon-vehicletype-${response.type || ''}"></i></span>
                        ${response.brand || ''} ${response.name || ''} - ${response.color || ''} ${response.year || ''}
                        ${business}
                    </span>
                `);
            }).fail((err) => {
                console.error(err);
                $carDetails.html(`<span class="help-block">We kunnen geen informatie vinden voor kenteken '${$licensePlateInput.val()}'.</span>`);
                $formGroup.addClass('has-error');
            }).always(() => {
                $licensePlate.removeClass('is-loading');
            });
        });
    }
}
