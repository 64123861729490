import $ from 'jquery';

// Business
import FlyersPriceCalculator from './modules/flyers-price-calculator';
import ReplacementVehicleForm from './modules/replacement-vehicle-form';

// Core
import ScrollTo from '@core/src/js/modules/scroll-to';
import Notifications from '@core/src/js/modules/notifications';
import AddressRetriever from '@core/src/js/modules/address-retriever';
import Maps from '@core/src/js/modules/maps';

// Dashboard core
import StateEnhancer from '@core-dashboard/src/js/modules/state-enhancer';
import Timeago from '@core-dashboard/src/js/modules/timeago';
import Galleries from '@core-dashboard/src/js/modules/galleries';
import FHCollections from '@core-dashboard/src/js/modules/fh-collections';
import FHFileUpload from '@core-dashboard/src/js/modules/fh-file-upload';
import UploadEnhancer from '@core-dashboard/src/js/modules/upload-enhancer';
import AddRequestHandler from '@core-dashboard/src/js/modules/add-request-handler';

$('html').removeClass('no-js').addClass('js');
$(document).ready(() => {
    new StateEnhancer;
    new FlyersPriceCalculator;
    new ReplacementVehicleForm;
    new Notifications;
    new AddressRetriever;
    new Maps;
    new Timeago;
    new ScrollTo;
    new Galleries;
    new FHCollections;
    new FHFileUpload;
    new UploadEnhancer;
    new AddRequestHandler;
});
