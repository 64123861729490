import $ from 'jquery';

export default class FHFileUpload {
    constructor(selector = '.js-file-upload') {
        const $fileUploads = $(selector);
        if ( !$fileUploads.length ) {
            return;
        }

        this.initialize();

        // Cropper
        if ( $fileUploads.siblings('.js-crop').length ) {
            this.initializeCrop();
        }
    }

    initialize() {
        require.ensure([], (require) => {
            const fileUpload = require('fh-file-upload');
            fileUpload.init();
        });
    }

    // Crop
    initializeCrop() {
        require.ensure([], (require) => {
            require('cropper');

            this.setCrop();
            this.setCropTriggers();
        });
    }

    setCropTriggers() {
        if ( this.cropTriggersSet ) {
            return;
        }

        this.cropTriggersSet = true;
        $(document).on('click', '.js-crop-trigger', (e) => {
            e.preventDefault();

            const $cropTrigger = $(e.currentTarget),
                  $target = $($cropTrigger.data('target')),
                  $img = $target.find('.crop-image'),
                  options = $.extend({}, $img.data('cropConfig'));

            $target.addClass('is-active');

            if ( $cropTrigger.data('original')) {
                $img.attr('src', `${$img.data('dir')}/${$cropTrigger.data('original')}`);
            }

            if ( $img.data('cropSet') ) {
                $img.cropper('destroy');
            }

            $img.cropper(options);
            $img.data('cropSet', true);
        });
    }

    setCrop() {
        $('.js-crop').not('.crop-initialized').each((i, crop) => {
            const $crop = $(crop),
                  $img = $crop.find('img');

            $crop.find('.js-cancel-crop').on('click', (e) => {
                e.preventDefault();
                $crop.removeClass('is-active');
            });

            $crop.find('.js-crop-image').on('click', (e) => {
                e.preventDefault();
                this.cropImage($img, $crop);
            });

            $crop.addClass('crop-initialized');
        });
    }

    cropImage($img, $crop) {
        const data = $img.cropper('getData', true);

        $.ajax($img.data('realtimeCropUrl'), {
            method: 'POST',
            data: {
                crop: {
                    w: data.width,
                    h: data.height,
                    x: data.x,
                    x2: data.x + data.width,
                    y: data.y < 0 ? 0 : data.y,
                    y2: data.y + data.height
                },
                image: $img.attr('src').replace(/.*\//, '')
            }
        }).done(() => {
            $crop.removeClass('is-active');

            let $croppedImage = $crop.next('.js-file-upload').find('img');
            $croppedImage.attr('src', `${$croppedImage.attr('src').replace(/\?.*/, '')}?${new Date().getTime()}`);
        });
    }
}
