/* global google */
import $ from 'jquery';
import { merge, isArray, get } from 'lodash';
import { throttle } from 'throttle-debounce';

export default class Maps {
    constructor(selector = '.js-map') {
        const $maps = $(selector);
        if ( !$maps ) {
            return;
        }

        // Variables
        this.$window = $(window);

        // Fetch key
        this.key = $('body').data('google-maps-key');

        // Inject Google Maps and execute functions when it's available
        const throttleDelay = 300;
        this.injectLibrary();
        this.whenAvailable([
            this.drawMaps.bind(this, $maps),
            () => $(document).on('scroll resize', throttle(throttleDelay, this.drawMaps.bind(this, $maps))).trigger('resize'),
            () => $('input, select').on('change', throttle(throttleDelay, this.drawMaps.bind(this, $maps)))
        ]);
    }

    injectLibrary() {
        if ( this.isInjecting ) {
            return;
        }

        if ( !this.key ) {
            console.warn('No Google Maps API key present on <body> element!');
        }

        this.isInjecting = true;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}`;
        document.body.appendChild(script);
    }

    whenAvailable(functions) {
        const interval = 250;
        const isAvailable = typeof google === 'object' && typeof google.maps === 'object';

        // Check if Google Maps is available, if it's not available yet we'll retry in x ms
        if ( !isAvailable ) {
            return setTimeout(this.whenAvailable.bind(this, functions), interval);
        }

        // If it's available, execute all functions
        if ( !isArray(functions) ) {
            functions = [functions];
        }

        functions.map((f) => f());
    }

    drawMaps($maps) {
        if ( !$maps.length ) {
            return;
        }

        $maps.filter(':not(.is-initialized)').each((i, map) => {
            const $map = $(map);

            // Skip if it's not visible to improve performance and reduce requests
            if ( !this.isVisible($map) ) {
                return;
            }

            // Make sure latitude and longitude are specified
            const data = $map.data();
            if ( !data.lat || !data.lng ) {
                $map.addClass('is-error');
                return console.error('No latitude and/or longitude were specified!');
            }

            // Center & zoom
            const defaultZoom = 8;
            let bounds,
                zoom = data.zoom || defaultZoom,
                center = { lat: data.lat, lng: data.lng };

            // Update center based on markers if there are multiple
            if ( typeof data.markers !== 'undefined' && data.markers.length > 1 ) {
                bounds = new google.maps.LatLngBounds();
                data.markers.map((marker) => bounds.extend(new google.maps.LatLng(marker.lat, marker.lng)));
                center = bounds.getCenter();
            }

            // Render map
            map = new google.maps.Map(map, merge({
                center: center,
                zoom: zoom,
                disableDefaultUI: true,
                scrollwheel: false,
                zoomControl: true,
                backgroundColor: 'none'
            }, data.options || {}));

            google.maps.event.addDomListener(window, 'resize', () => {
                map.setCenter(center);
                map.fitBounds(bounds);
            });

            // Pinpointing maps: select a position to add a marker and update a .js-longitude and .js-latitude input
            if ( $map.hasClass('map-pinpoint') ) {
                google.maps.event.addListener(map, 'click', (e) => {
                    if ( typeof map.marker !== 'undefined' ) {
                        map.marker.setMap(null);
                    }

                    map.marker = new google.maps.Marker({
                        position: e.latLng,
                        map: map
                    });

                    // Update fields
                    const $container = $map.parent();
                    $container.find('.js-latitude').val(e.latLng.lat());
                    $container.find('.js-longitude').val(e.latLng.lng());
                });
            }

            // Update zoom based on bounds
            if ( bounds ) {
                map.fitBounds(bounds);
            }

            // Update state
            $map.removeClass('is-error').addClass('is-initialized');

            // Markers
            if ( data.markers ) {
                this.addMarkers(map, data.markers);
            }
        });
    }

    addMarkers(map, markers) {
        if ( !markers.length ) {
            return;
        }

        require.ensure([], (require) => {
            const CustomMarker = require('@core/src/js/modules/maps/custom-marker').default;

            markers.map((marker) => {
                const { type, lat, lng, title } = marker;
                const latLng = new google.maps.LatLng(lat, lng);

                const { classes, styles } = ((marker) => {
                    if ( type === 'marker' || type === 'consumer' ) {
                        return {
                            classes: ['marker-dot']
                        };
                    }

                    if ( type === 'salvor' || type === 'transportation' ) {
                        return {
                            classes: ['marker-dot', 'is-pulsating', 'marker-salvor'],
                            styles: {
                                attr: 'backgroundImage',
                                value: `url('${marker.data.image}')`
                            }
                        };
                    }

                    if ( type === 'marker' || type === 'consumer' ) {
                        return {
                            classes: ['marker-dot', 'marker-dot-checkered']
                        };
                    }
                })(marker);

                const hlm = get(marker, 'data.hlm') && marker.data.hlm;
                new CustomMarker(latLng, map, classes.concat(marker ? marker.classes : []), styles, title, hlm);
            });
        });
    }

    isVisible($el) {
        if ( !$el.is(':visible') ) {
            return false;
        }

        // Check if element is in viewport if it's visible
        const rect = $el[0].getBoundingClientRect();
        return (
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= this.$window.height() &&
            rect.left <= this.$window.width()
        );
    }
}
