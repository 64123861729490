import $ from 'jquery';

export default class UploadEnhancer {
    constructor(selector = '.js-enhanced-upload') {
        this.$fields = $(selector).find('input[type="file"]');
        if ( !this.$fields.length ) {
            return;
        }

        this.attachListeners();
    }

    attachListeners() {
        this.$fields.on('dragenter', (e) => {
            $(e.currentTarget).addClass('is-dragging');
        }).on('dragleave dragend drop', (e) => {
            $(e.currentTarget).removeClass('is-dragging');
        }).on('change', (e) => {
            const $target = $(e.currentTarget);
            const $state = $target.siblings('.form-upload-state');

            let state;
            switch ( e.currentTarget.files.length ) {
                case 0:
                    state = 'Geen bestanden geselecteerd';
                    break;
                case 1:
                    state = e.currentTarget.value.split('\\').pop();
                    break;
                default:
                    state = `${e.currentTarget.files.length} bestanden geselecteerd`;
            }

            $state.html(state);
            $target.blur();
        });
    }
}
