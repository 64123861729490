var $ = require('jquery');

export default class ScrollTo {
    constructor(selector = '.js-scroll-to') {
        this.$scrollTos = $(selector);
        if ( !this.$scrollTos.length ) {
            return;
        }

        this.initializeEventHandlers();
        this.initializeCurrentURL();
    }

    initializeEventHandlers() {
        this.$scrollTos.on('click', (e) => {
            e.preventDefault();

            const href = $(e.currentTarget).attr('href');
            this.scrollTo($(href));
        });
    }

    initializeCurrentURL() {
        if ( !window.location.hash ) {
            return;
        }

        this.scrollTo($(window.location.hash));
    }

    scrollTo($el, duration = 500) { // eslint-disable-line no-magic-numbers
        if ( !$el.length ) {
            return;
        }

        $('html, body').stop().animate({
            scrollTop: $el.offset().top
        }, duration);
    }
}
