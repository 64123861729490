import $ from 'jquery';

export default class FlyersPriceCalculator {
    constructor(selector = '.js-flyers-price-calculator') {
        this.$calculators = $(selector);
        if ( !this.$calculators.length ) {
            return;
        }

        this.initialize();
    }

    initialize() {
        this.$calculators.on('change', (e) => {
            const $calculator = $(e.currentTarget),
                  $container = $calculator.closest('.flyers-price-calculator'),
                  $price = $container.find('.flyers-price-calculator-price');

            if ( !$calculator.val() ) {
                $price.text('').hide();
                return;
            }

            $container.addClass('is-loading');
            $.ajax({
                url: $calculator.data('url'),
                data: {
                    amount: $calculator.val()
                }
            }).done((response) => {
                $price.show().text(response.totalPrice);
            }).always(() => {
                $container.removeClass('is-loading');
            }).fail((err) => {
                console.error(err);
                $price.hide().text('');
            });
        });

        this.$calculators.each((i, calculator) => {
            const $calculator = $(calculator),
                  $container = $calculator.parent().wrapAll('<div class="flyers-price-calculator"/>').parent();

            $container.append('<div class="flyers-price-calculator-price"/>');
        }).trigger('change');
    }
}
