import $ from 'jquery';

export default class ReplacementVehicleForm {
    constructor(selector = '.js-replacement-vehicle-form-state', contentSelector = '.js-replacement-vehicle-form-content') {
        this.selector = selector;
        this.contentSelector = contentSelector;

        this.$state = $(this.selector);
        if ( !this.$state.length ) {
            return;
        }

        this.$state.on('change', this._handleChange.bind(this)).trigger('change');
    }

    _handleChange(e) {
        const $state = $(e.currentTarget);
        $state.closest('form').find(this.contentSelector).toggle(Boolean(Number($state.val())));
    }
}
